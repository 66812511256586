import { motion } from 'framer-motion';
import { css, cx } from '@linaria/core';
import React from 'react';
import { Menu, MenuItem } from '../../components/styles';
import { Colors } from '../../theme/constants';
import { TrendingFilter } from '@commonstock/common/src/api/recommendation';
import { isLoggedIn } from '@commonstock/common/src/auth';
type DropDownProps = {
  setType: (type: TrendingFilter) => void;
  type: TrendingFilter;
};
const animate = {
  top: '1.4rem'
};
const initial = {
  top: '2rem'
};

const TrendingDropDown = ({
  setType,
  type
}: DropDownProps) => {
  const isFollowing = type === TrendingFilter.Following;
  const isGlobal = type === TrendingFilter.Global;
  const isTop = type === TrendingFilter.Top;

  const onClickTop = () => setType(TrendingFilter.Top);

  const onClickGlobal = () => setType(TrendingFilter.Global);

  const onClickFollowing = () => setType(TrendingFilter.Following);

  return <motion.div animate={animate} style={initial} className={wrapperClass}>
      <Menu className={menuClass}>
        <MenuItem onClick={onClickGlobal} className={cx(isGlobal && 'active')}>
          Global
        </MenuItem>
        {isLoggedIn() && <MenuItem onClick={onClickFollowing} className={cx(isFollowing && 'active')}>
            Following
          </MenuItem>}
        <MenuItem onClick={onClickTop} className={cx(isTop && 'active')}>
          Top Investors
        </MenuItem>
      </Menu>
    </motion.div>;
};

export default TrendingDropDown;
const wrapperClass = "welo8kf";
const menuClass = "mkrtayc";

require("../../../.linaria-cache/packages/oxcart/src/scopes/trending/TrendingDropDown.linaria.module.css");